import React from "react";
import { NavLink } from "react-router-dom";

class Projects extends React.Component {
    render() {
        return (
            
            <div id="portfolio" className="row" data-layout="fitRows">
            {
                this.props.projects.map((data, index) => {
                    return (
                        <article key={data.author} className={`portfolio-item col-sm-12 col-md-4 col-12 ${data.tags}`}>
                            <div className="grid-inner">
                                <div className="portfolio-image">
                                    <NavLink to={`/project?author=${data.authorID}`}>
                                        <img src={`/media/covers/${data.projectSrc}`} alt={data.title} />
                                    </NavLink>
                                    <div className="bg-overlay">
                                        <div className="bg-overlay-content dark" data-hover-animate="fadeIn">
                                            <NavLink to={`/project?author=${data.authorID}`} className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350">
                                                <i className="icon-chevron-right "></i>
                                            </NavLink>
                                        </div>
                                        <div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
                                    </div>
                                </div>
                                <div className="portfolio-desc">
                                    <h3>
                                        <NavLink to={`/project?author=${data.authorID}`} state={{ from: "projects" }}>
                                        {data.title}
                                        </NavLink>
                                    </h3>
                                    <span>{data.author}</span>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
            </div>
        );
    }
}

export default Projects;