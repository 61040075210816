import React from "react";
import Projects from "./projects"

import "./landing.scss";

class Landing extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			projects: []
		}

		this.props.setAuthor("");

		this.fetchProjects = this.fetchProjects.bind(this)

	};

	componentDidUpdate() {
		window.SEMICOLON.reactProjectLandingReload.init();
	}

	fetchProjects() {
		var me = this;
		fetch("https://idj9gtklu1.execute-api.us-east-2.amazonaws.com/default/gened_fetchProjects")
		.then((res) => {
			return res.json();
		})
		.then((json) => {
			console.log(json)
			me.setState({ projects : json}, () => me.forceUpdate());
		})
		.catch((reason) => {
			console.log(reason);
		});
	}

	componentDidMount(){
		this.fetchProjects();
	}

	render () {
		return (
			<section id="content">
				<div className="content-wrap">
					<div className="container clearfix">
						<div className="grid-filter-wrap">
							<ul className="grid-filter" data-container="#portfolio">
								<li className="activeFilter"><a href="#" data-filter="*">Show All</a></li>
								<li><a href="#" data-filter=".pf-essay,.pf-continuous-text-written-in-paragraphs,.pf-block-text">Essays</a></li>
								<li><a href="#" data-filter=".pf-image,.pf-illustrations">Illustrations</a></li>
								<li><a href="#" data-filter=".pf-video">Video</a></li>
								<li><a href="#" data-filter=".pf-audio">Audio</a></li>
								<li><a href="#" data-filter=".pf-other-non-textual-form">Others</a></li>
							</ul>

							<a href="#" onClick={this.fetchProjects} className="refresh-button button button-3d button-rounded button-white button-light"><i class="icon-repeat"></i>Refresh</a>
						</div>

						{this.state.projects && (<Projects projects={this.state.projects} />)}
					</div>
				</div>
			</section>
		);
	};
}

export default Landing;