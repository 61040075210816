import React from "react";

import "./footer.scss";

class Footer extends React.Component {
	render () {
		return (
			<footer id="footer" className="dark">
				<div id="copyrights">
					<div className="container">
						<div className="row col-mb-30">
							<div className="col-md-6 text-center text-md-left">
								Copyrights &copy; {(new Date().getFullYear())} <br />
							</div>
						</div>
					</div>
				</div>

			</footer>
		);
	};
}

export default Footer;