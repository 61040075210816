import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Landing from "./components/landing";
import Header from "./components/header";
import Footer from "./components/footer";
import Project from "./components/project"

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      author: ""
    }
  };

  setAuthor = (name) => {
    this.setState({
      author: name
    });
  };

  render() {
    return (
      <Router>
        <div id="wrapper" className="clearfix">
          <Header author={this.state.author} />
          <Routes>
            <Route path="/" element={<Landing setAuthor={this.setAuthor} />} />
            <Route path="/project" element={<Project setAuthor={this.setAuthor} />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App;
