import React from "react";
import { NavLink } from "react-router-dom";

import "./header.scss";

class Header extends React.Component {
	constructor(props) {
		super(props);
	};

	hasAuthor() {
		return this.props.author && this.props.author != "";
	}

	render () {
		return (
			<section id="page-title">
				<div className="container clearfix">
					<h1>GENED 1091: classical Chinese Ethical and Political Theory</h1>
					<div id="portfolio-navigation">
						{this.hasAuthor() &&
							<NavLink to="/">
								<i className="icon-chevron-sign-left"></i>
							</NavLink>
						}
						<NavLink to="/">
							<i className="icon-home"></i>
						</NavLink>
					</div>
				</div>
			</section>
		);
	};
}

export default Header;