import React from "react";
import ProjectDetail from "./projectDetail"
import ProjectCarousel from "./projectCarousel"

class Project extends React.Component {
    constructor(props) {
        super(props);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let author = params.get('author');
        this.state = {
            author: author,
            project: {},
            projects: []
        }
        this.props.setAuthor(author);
    };

    componentDidMount(){
        this.fetchData();
    }

    updateAuthor(authorID) {
        this.setState({ author: authorID }, function() {
            window.location.reload();
        });
    }

    fetchData() {
        // fetch specific content
        fetch("https://avl4x45qvc.execute-api.us-east-2.amazonaws.com/default/gened_fetchProjectByAuthor?author=" + this.state.author)
        .then((res) => {
            return res.json();
        })
        .then((json) => {
            this.setState({ project: json })
        })
        .catch((reason) => {
        
        });

        // fetch related projects
        fetch("https://idj9gtklu1.execute-api.us-east-2.amazonaws.com/default/gened_fetchProjects")
        .then((res) => {
            return res.json();
        })
        .then((json) => {
            var projects = []
            for (var i in json) {
                var project = json[i];
                if (project['authorID'] !== this.state.author) {
                    projects.push(project);
                }
            }
            this.setState({ projects : projects})
        })
        .catch((reason) => {
        
        });
    }

    render () {
        return (
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <div className="row gutter-40 col-mb-80">
                            <div className="postcontent col-lg-12">
                                <ProjectDetail project={this.state.project} />
                            </div>
                        </div>

                        <h4>Other Projects:</h4>
                        <ProjectCarousel projects={this.state.projects} onRelatedProjectClick={this.updateAuthor.bind(this)} />

                    </div>
                </div>
            </section>
        );
    };
}

export default Project;