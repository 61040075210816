import React from "react";
import { NavLink } from "react-router-dom";

class ProjectCarousel extends React.Component {

    renderProjects() {
        return this.props.projects?.map((data, index) => (
            <div key={data.author} className="oc-item">
                <div className="portfolio-item">
                    <div className="portfolio-image">
                        <NavLink to={`/project?author=${data.authorID}`} onClick={() => this.props.onRelatedProjectClick(data.authorID)}>
                            <img src={`/media/covers/${data.projectSrc}`} alt={data.title} />
                        </NavLink>
                        <div className="bg-overlay">
                            <div className="bg-overlay-content dark" data-hover-animate="fadeIn">
                                <NavLink to={`/project?author=${data.authorID}`} onClick={() => this.props.onRelatedProjectClick(data.authorID)} className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350">
                                    <i className="icon-chevron-right "></i>
                                </NavLink>
                            </div>
                            <div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
                        </div>
                    </div>
                    <div className="portfolio-desc">
                        <h3>
                            <NavLink to={`/project?author=${data.authorID}`} onClick={() => this.props.onRelatedProjectClick(data.authorID)}>{data.title}</NavLink>
                        </h3>
                    </div>
                </div>
            </div>
        ));
    }

    render () {
        return (
            <div id="related-portfolio" className="owl-carousel portfolio-carousel carousel-widget" data-margin="20" data-pagi="false" data-items-xs="1" data-items-sm="2" data-items-md="3" data-lazyload="true" data-items-lg="4">
                {this.renderProjects()}
            </div>
        );
    };
}

export default ProjectCarousel;